import React from "react";
import { Helmet } from "react-helmet";
import { StaticQuery, graphql } from "gatsby";

const Seo = ({description, keywords, title, image, url, author }) => {
    return (
        <StaticQuery
            query={detailsQuery}
            render={data => {
                const metaDescription = "Dépanage et réparation informatique sur goussainville pour particulier. Nous prenons en charge les téléphones, les ordinateurs portables... Le devis est gratuit. Prendre contact au 06 50 71 21 18 ou par mail à depanne.informatique95@gmail.com" || data.site.siteMetadata.description;
                const metaTitle = "DepanGouss - Dépanage, réparation, et services informatiques sur goussainville" || data.site.siteMetadata.title;
                const metaAuthor = "DepanGouss" || data.site.siteMetadata.author;
                const metaUrl = "https://depangouss.com/" || process.env.GATSBY_PROXY || data.site.siteMetadata.url;
                const metaImage = image || process.env.GATSBY_PROXY + data.file.childImageSharp.fixed.src;
                const metaKeywords = keywords || ["dépannage informatique goussainville", "dépannage informatique 95", "DepanGouss", "Dépannage informatique", "Informatique", "depangouss", "depannage informatique goussainville", "dépanage goussainville", "dépannage informatique", "depan gouss"];
                return (
                    <Helmet
                        title={metaTitle}
                        meta={[
                            {
                                name: `description`,
                                content: metaDescription
                            },
                            {
                                property: `og:title`,
                                content: metaTitle
                            },
                            {
                                property: `og:description`,
                                content: metaDescription
                            },
                            {
                                property: `og:type`,
                                content: `website`
                            },
                            {
                                property: `og:image`,
                                content: metaImage
                            },
                            {
                                property: `og:local`,
                                content: `fr`
                            },
                            {
                                property: `og:url`,
                                content: metaUrl
                            },
                            {
                                name: `twitter:card`,
                                content: `summary`
                            },
                            {
                                name: `twitter:creator`,
                                content: metaAuthor
                            },
                            {
                                name: `twitter:title`,
                                content: metaTitle
                            },
                            {
                                name: `twitter:description`,
                                content: metaDescription
                            },                         
                          ].concat(
                              metaKeywords && metaKeywords.lenght > 0 ? {
                                  name: `keywords`,
                                  content: metaKeywords.join(`, `),
                              }
                              : []
                        )}
                        link={[
                            {
                                rel: `canonical`,
                                href: "https://depangouss.com/"
                            }
                        ]}
                    />
                );
            }}
        />
    );
};

const detailsQuery = graphql`
    query DefaultSEOQuery {
        site {
            siteMetadata {
                title
                description
                author
                image
            }
        }
        file(relativePath: {eq: "icon.png"}) {
            childImageSharp {
              fixed(fit: CONTAIN, height: 315, width: 600, background: "#fff") {
                src
              }
            }
          }
    }
`;

export default Seo;