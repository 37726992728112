import React from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import Button from '../Button';
import logo from "./../../assets/logo.png";
import "./../../css/header.css";

const Header = () => (
  <header className="sticky top-0 bg-white shadow">
    <div className="container flex flex-col sm:flex-row justify-between items-center mx-auto py-4 px-8">
      <div className="flex items-center text-2xl">
        <AnchorLink offset='100' className="px-4" href="#home">
          <div className="logoWrapper mr-3">
            <img className="logo" src={logo} alt="logo"/>
          </div>
        </AnchorLink>
      </div>
      <div className="flex mt-4 sm:mt-0">
        <AnchorLink className="px-4" href="#how">
          Commment ça marche
        </AnchorLink>
        <AnchorLink className="px-4" href="#needs">
          Besoins d'un expert
        </AnchorLink>
        <AnchorLink className="px-4" href="#testimonials">
          Avis
        </AnchorLink>
      </div>
      <div className="hidden md:block">
        <Button className="text-sm">06 50 71 21 18</Button>
      </div>
    </div>
  </header>
);

export default Header;
